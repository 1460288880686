@import '../../mixins';
@import '../../variables';

// $grid-breakpoints             : ( xs: 0, xm: 420px, sm: 576px, md: 768px, lg: 991px, xl: 1200px, xxl: 1500px) !default;
.auth-card {
    @include displayFlex($display: flex, $direction: column, $align-items: center);
    @include size($min-height: 650px, $max-height: 800px, $min-width: 405px, $max-width: 35%);
    @include position($position: absolute, $top: 50%, $left: 100%);
    transform : translate(-50%, -50%);
    @include media-breakpoint-down(md) {
         @include position($position: absolute, $top: 0%, $left: 0%);
        transform: translate(0, 0);
        @include blur($background-color: #00000000);
        @include size($width: 100vw, $height: 100vh, $max-width: unset, $max-height: unset);
    }
    @include padding($top: 64px, $left: 24px, $right: 24px, $bottom: 24px);
    @include blur($background-color: $conversed-white);

    /* diffuse_shadow20 */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.title {
    /* Log in */
    /* Roboto/h1 */
    align-self: flex-start;
    @include font($size: 40px, $weight: 500, $line-height: 48px, $font-style: normal, $color: $conversed-primary);
    /* Inside Auto Layout */
    @include margin($top: 50px, $left: 25px);
}

.form-error {
    @include font($color: $conversed-danger, $size: small, $font-style: normal, $weight: normal);
    @include margin($top: -10px);
}

.empty-error {
    @include margin($top: 18px);
}

.password-instructions {
    @include font($size: 13px, $line-height: 18px, $font-style: normal);
    @include margin($bottom: 30px);

}

.reset-instructions {
    @include font($size: 13px, $line-height: 18px, $font-style: normal);
}

.login-form {
    align-self: flex-start;
    @include displayFlex($display: flex, $direction: column, $align-items: flex-start);
    @include padding($full: 24px);
    @include size($width: 95%, $max-height: 45%, $min-height: 25%, $height: 350px);
    @include position($position: static, $top: 244.99px, $left: 64px);

    &-auth-input {
        @include size($width: 100%);
        @include margin($top: 12px);

        &-label {
            /* username and password */
            // align-self: flex-start;
            @include font($size: 12px, $weight: normal, $transform: capitalize, $color: $conversed-text, $line-height: 120%);
        }

        input {
            // align-self: flex-start;
            @include size($max-width: 100%, $min-width: 90%, $height: 42px, $width: 100%);
            @include blur($background-color: $conversed-white);
            @include borderBox($border: 1px solid $border-black, $radius: 2px);

            margin: 8px 0;
        }
    }

    &-description {
        @include font($color: $conversed-text, $size: 16px, $line-height: 150%, $font-style: normal, $weight: normal);
        @include margin($bottom: 10%);
    }

    &-frame {
        /* Frame 32 */

        /* Auto Layout */
        @include displayFlex($display: flex, $direction: column, $align-items: flex-start);
        @include padding($full: 0px);
        @include margin($top: 16px);

        /* Inside Auto Layout */

        &-buttons {
            /* buttons */
            @include displayFlex($display: flex, $direction: row, $align-items: flex-start);
            @include padding($full: 0px);
            @include margin($full: 8px 0px);

            &-login {
                @include margin($top: 16px);
                @include padding($full: 0px 24px);
            }

            &-options {
                @include size($width: 100%);
                @include displayFlex($display: flex, $justify-content: space-between, $direction: row, $align-items: center);

                &-forgot {
                    @include font( $size: 13px, $font-style: normal, $weight: normal, $line-height: 120%, $color: $conversed-primary );
                    @include margin($full: 0px 16px);
                    text-decoration-line: underline;
                }
            }
        }

        button {
            @include displayFlex($display: flex, $direction: row, $align-items: center, $justify-content: center);
            @include size($width: 90px, $height: 40px);
            left         : 0;
            top          : 0;
            white-space  : nowrap;

            /* conversed/primary */
            @include blur($background-color: $conversed-primary);
            color        : $conversed-white;
            border-radius: 2px;
            @include margin($top: 16px);
            @include padding($full: 0px 24px);
        }

        &-signup {
            /* Don’t have an account? Sign up! */
            @include margin($top: 24px);
            @include font($size: 14px, $weight: 400, $color: $conversed-primary, $line-height: 150%, $text-align: center);
            order: 5;
        }
    }
}
