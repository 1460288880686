@import '../../variables';
@import '../../mixins';

.no-domain {
  font-weight: 100;
  font-size: 14px;
  margin: 0;
}
.cv-show-hide-button {
  width: fit-content;
  .cv-show-hide {
    display: flex;
    align-items: center;
    span {
      color: $conversed-primary;
      &:nth-child(1) {
        font-size: 16px;
        margin-right: 8px;
      }
      &:nth-child(2) {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
}

.cv-whitelisted-domains {
  li {
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      margin: 0 0 0 8px;
      line-height: 1.7;
    }
    &::before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: $conversed-text;
    }
  }
}
