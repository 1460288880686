@import '../../mixins';
@import '../../variables';

.wrapper {
    @include size($width: 100%);
}

.copyright {
    @include font($size: 12px, $font-style: normal, $weight: 400, $line-height: 14px, $text-align: left);
    @include margin($top: auto, $right: auto, $bottom: 16px, $left: 16px);
    align-self    : flex-end;
    letter-spacing: 0;
}

// Auth column (col 1)
.auth {
    @include size($height: 100vh);
    @include position($position: relative);
    // Align copyright and other elements using flex
    @include displayFlex($display: flex, $direction: column);
}

.background {
    @include background-image( $background-image-path: '../../images/auth_background.png', $position: 100%, $repeat: no-repeat, $size: cover );
    // Have image fill column
    @include size($min-height: 100vh);

    // Hide image on small screens
    @include media-breakpoint-down(md) {
         @include displayFlex($display: none);
    }
}
