@import '../../../variables';

.private-note {
    display       : flex;
    flex-direction: row;
    max-width     : 60%;
    padding       : 8px 12px;
    margin        : 2px 16px;
    box-shadow    : inset 0 -1px 0 rgba(0, 0, 0, 0.1);

    p {
        font-size  : 14px;
        font-weight: 400;
        line-height: 150%;
    }

    span {
        margin-right: 8px;
        /* display: flex; */
        /* align-items: center; */
        font-size   : 14px;
        padding     : 3px 0;
        color       : $conversed-primary;
    }

    background    : $conversed-note;
    border-radius : 8px 0 8px 8px;
    align-self    : flex-end;
    align-items   : flex-end;
}
