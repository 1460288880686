@import '../../../mixins';
@import '../../../variables';

.panel-header {
    position     : relative;
    padding      : 12px 25px 12px 16px;
    background   : $conversed-white;
    display      : flex;
    align-items  : center;
    border-left  : 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    @include media-breakpoint-down(sm) {
        position: absolute;
        top     : 60px;
        right   : 0;
        width   : 100vw;
        height  : 51px;
    }

    .client {
        display    : flex;
        align-items: center;
        @media only screen and (max-width: 1100px) {
            p {
                max-width    : 280px;
                white-space  : nowrap;
                overflow     : hidden;
                text-overflow: ellipsis;

                .app-bar-open & {
                    max-width : 80px;
                    transition: all .6s;
                    @media only screen and (max-width: 1024px) {
                        max-width: 100%;
                    }
                }
            }
        }

        .user-avatar {
            width       : 30px;
            height      : auto;
            margin-right: 8px;
        }

        a {
            line-height: 1;
        }

        .arrow-back {
            display: none;
            @include media-breakpoint-down(sm) {
                display: inline-block;
                color  : $conversed-text;
            }
        }

        .name-user {
            text-decoration: none;
        }
    }

    .panel-navbar {
        padding-top   : 0;
        padding-bottom: 0;

        .cv-container {
            padding-right: 0;
            padding-left : 0;

            .cv-navbar-toggle {
                padding     : 4px 10px;
                border-color: transparent;

                &:focus {
                    box-shadow: none;
                }
                @include media-breakpoint-down(md) {
                    display: flex;
                }

                span {
                    position        : relative;
                    background-image: none;
                    background      : $conversed-gray;
                    width           : 4px;
                    height          : 4px;
                    border-radius   : 4px;

                    &::after, &::before {
                        content      : '';
                        position     : absolute;
                        top          : -6px;
                        left         : 0;
                        background   : $conversed-gray;
                        width        : 4px;
                        height       : 4px;
                        border-radius: 4px;
                    }

                    &::after {
                        top: 6px;
                    }
                }
            }

            .cv-nav-collapse {
                @include media-breakpoint-down(md) {
                    position     : absolute;
                    right        : 0;
                    top          : 30px;
                    border-radius: 2px;
                    background   : $conversed-white;
                    box-shadow   : (0px 4px 8px rgba(0, 0, 0, 0.3));
                    z-index      : 100;
                }

                .nav-wrapper {
                    > * {
                        &:not(:last-child) {
                            margin: 0 16px 0 0;
                            @include media-breakpoint-down(md) {
                                margin: 0;
                            }
                        }

                        &:last-child {
                            margin: 0 0 0 -8px;
                            @include media-breakpoint-down(md) {
                                margin: 0;
                            }
                        }
                    }
                }
            }

        }
    }

    .wrapper {
        display    : flex;
        align-items: center;

        .segmented-control {
            display     : flex;
            margin-right: 8px;
            @include media-breakpoint-down(md) {
                margin-right: 0;
            }

            .button-default {
                width        : 80px;
                height       : 32px;
                border       : none;
                background   : $conversed-light-grey;
                color        : $conversed-gray;
                cursor       : default;
                border-radius: 2px 0 0 2px;

                p {
                    font-size: 13px;
                    color    : $conversed-gray;
                }

                &.button-open {
                    background   : $conversed-secondary;
                    border-radius: 2px 0 0 2px;
                    cursor       : pointer;

                    p {
                        font-size: 13px;
                        color    : $conversed-white;
                    }
                }
            }

            .button-resolve {
                background     : $conversed-success;
                border-radius  : 2px;
                display        : flex;
                align-items    : center;
                justify-content: center;
                cursor         : pointer;
                @include media-breakpoint-down(md) {
                    justify-content: space-between;
                    width          : 160px;
                    border-radius  : 0;
                }

                span {
                    color       : $conversed-white;
                    font-size   : 14px;
                    margin-right: 2px;
                    order       : 1;
                    @include media-breakpoint-down(md) {
                        margin-right: 0;
                        margin-left : 2px;
                    }
                }

                p {
                    order    : 2;
                    font-size: 13px;
                    color    : $conversed-white;
                    @include media-breakpoint-down(md) {
                        order: 0;
                    }
                }
            }
        }

        span {
            color: $conversed-gray;
        }

    }
}
