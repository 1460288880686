@import '../../../mixins';
@import '../../../variables';

.profile-dropdown {
    span {
        font-size  : 13px;
        line-height: 1.2;
        color      : $conversed-text;
    }

    &.button {
        border    : none;
        background: $conversed-white !important;

        &::after {
            display: none;
        }

        &:hover {
            border: none;
        }

        &:focus {
            box-shadow: none !important;
        }

        .user-avatar {
            max-width   : 30px;
            margin-right: 12px;
            @include media-breakpoint-down(xm) {
                margin-right: 0;
            }
        }

        .user-name-type {
            display       : none;
            flex-direction: column;
            align-items   : flex-start;
            font-size     : 16px;
            font-weight   : 500;
            line-height   : 1.2;
            color         : $conversed-text;
            @include media-breakpoint-up(sm) {
                display: flex;
            }

            span {
                font-size  : 11px;
                color      : $conversed-primary;
                font-weight: 400;
            }
        }
    }

    &.menu {
        min-width    : 240px;
        padding      : 0;
        border-radius: 1px;
        border       : none;
        box-shadow   : 0 4px 8px 0 rgba(0, 0, 0, 0.3);

    }

    .item, .item-header {
        padding: 10px;

        &:hover {
            background: $conversed-dropdown-active;
        }

        &:active {
            background: $conversed-white;
        }
    }

    .item-header {
        cursor: default;

        &:hover {
            background: $conversed-white;
        }
    }

    .divider {
        margin: 0;
    }
}

.cv-user {
    display    : flex;
    align-items: center;
}

.user-avatar {
    max-width    : 45px;
    margin-right : 12px;
    border-radius: 50%;
}

.wrapper-top {
    position: relative;
    padding : 12px 0 0;

    .cv-status {
        position   : relative;
        margin-left: 16px;

        &::before {
            content         : "";
            position        : absolute;
            top             : 50%;
            left            : -16px;
            transform       : translateY(-50%);
            width           : 8px;
            height          : 8px;
            border-radius   : 50%;
            background-color: green;
        }
    }

    .user-name-type {
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
        font-size     : 16px;
        line-height   : 1.2;
        font-weight   : 500;
        color         : $conversed-text;

        span {
            font-size  : 11px;
            font-weight: 400;
            color      : $conversed-primary;
        }
    }
}

.account-settings, .choose-customer, .choose-language {
    display        : flex;
    align-items    : center;
    text-decoration: none;
    margin         : 0;
    width          : 100%;

    span:first-child {
        margin-right: 8px;
        font-size   : 18px;
    }

    span:nth-child(3) {
        margin-left: auto;
        font-size  : 18px;
    }
}

.log-out {
    display        : flex;
    align-items    : center;
    text-decoration: none;

    span:first-child {
        margin-right: 8px;
        font-size   : 18px;
    }
}

.item-toggler {
    width           : 100%;
    padding         : 10px;
    border          : none !important;
    background-color: $conversed-white !important;

    &::after {
        display: none !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.choose-language-menu {
    width  : 100%;
    padding: 0 !important;

    a {
        padding: 5px 10px !important;

        &:hover {
            background-color: $conversed-dropdown-active;
        }
    }
}
