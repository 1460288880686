@import '../../../../variables';

.message-in, .message-out {
    max-width : 60%;
    padding   : 8px 12px;
    margin    : 2px 16px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);

    p {
        font-size  : 14px;
        font-weight: 400;
        line-height: 150%;

        // TODO: Remove this once we find out how to remove the parent <p> on formatted text
        p {
            margin-top: 18px;
        }
    }

    // TODO: Consult with designer on this
    ul {
        margin: 12px;
    }
}

.message-in {
    background   : $conversed-light-grey;
    border-radius: 0 8px 8px 8px;
    align-self   : flex-start;
}

.message-out {
    background   : $conversed-light-blue;
    border-radius: 8px 0 8px 8px;
    align-self   : flex-end;
}
