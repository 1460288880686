@import "../../mixins";

.wrapper {
    @include displayFlex($display: flex, $direction: row);
    width     : 100%;
    min-height: 100vh;
    @include media-breakpoint-down(lg) {
        width: calc(100vw - 250px);
    }
    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.greeting-text {
    @include displayFlex($display: flex, $direction: row, $justify-content: center, $align-items: center);
}

.content-wrapper {
    width     : 100%;
    height    : calc(100vh - 60px);
    padding   : 40px 30px 10px;
    overflow-y: scroll;
}
