@mixin img-responsive() {
    display  : block;
    max-width: 100%;
    height   : auto;
}
@mixin hover-state() {
    &:active, &:hover {
        outline   : none;
        box-shadow: none;
        @content;
    }
}
@mixin hide-input() {
    position  : absolute;
    opacity   : 0;
    left      : -9999px;
    visibility: hidden;
}
@mixin conversed-checkboxes-radios( $container, $inner-container, $border, $radius, $pad-left: null, $radio-pos: null, $label-Hcolor: $conversed-primary ) {
    [type='checkbox'], [type='radio'] {
        &:checked, &:not(:checked) {
            @include hide-input();

            + label {
                position    : relative;
                display     : flex;
                height      : $container;
                height      : auto;
                padding-left: $pad-left;
                cursor      : pointer;
                @include hover-state() {
                    &::before {
                        border-color: $conversed-primary;
                    }

                    p {
                        color: $conversed-primary;
                    }
                }

                &::before {
                    position     : absolute;
                    content      : '';
                    top          : 0;
                    left         : 0;
                    width        : $container;
                    height       : $container;
                    background   : transparent;
                    border       : $border solid $conversed-text;
                    border-radius: $radius;
                    transition   : all .2s ease-in-out;
                    @include hover-state() {}
                }

                &::after {
                    position     : absolute;
                    content      : '';
                    top          : $radio-pos;
                    left         : $radio-pos;
                    width        : $inner-container;
                    height       : $inner-container;
                    background   : $conversed-primary;
                    border-radius: $radius;
                    transition   : all .2s ease-in-out;
                }

                .icon {
                    position       : absolute;
                    top            : 0;
                    left           : 0;
                    display        : flex;
                    justify-content: center;
                    align-items    : center;
                    width          : $container;
                    height         : $container;
                    font-size      : 10px;
                    color          : transparent;
                    z-index        : 1;
                    font-size      : $container;
                    transition     : all .2s ease-in-out;
                }

                p {
                    font-size : 13px;
                    color     : $conversed-text;
                    margin    : 0;
                    transition: all .2s ease-in-out;
                }
            }
        }

        &:not(:checked) + label:after {
            opacity  : 0;
            transform: scale(0);
        }

        &:checked {
            + label {
                &::before {
                    border: $border solid $conversed-primary;
                }

                &::after {
                    opacity  : 1;
                    transform: scale(1);
                }

                .icon {
                    color: $conversed-white;
                }

                // @include hover-state() {
                //   .icon {
                //     color: $conversed-primary;
                //   }
                // }
            }
        }
    }
}
