@import '../../mixins';
@import '../../variables';

.wrapper {
    padding   : 0 !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

}

.image-container {
    @include size($width: 60px, $height: 60px);
    @include displayFlex ($display:flex, $align-items:center, $justify-content:center);
    @include media-breakpoint-down(sm) {
         @include size($width: 47px, $height: 47px);
    }
    margin: 20px;

    img {
        width : 100%;
        height: auto;
    }
}

.title {
    margin: 0;
    @include font($weight: 500, $font-style: normal, $color: $conversed-primary, $size:23px);
    @include media-breakpoint-down(sm) {
        font-size: 19px;
    }
}
