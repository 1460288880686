@import 'variables';

.Toastify__toast--success {
    background: $conversed-success !important;
}

.Toastify__toast--success {
    background: $conversed-success !important;
}

.Toastify__toast--warning {
    background: $conversed-warning !important;
}

.Toastify__toast--error {
    background: $conversed-danger !important;
}
