@import '../../../scss/variables';
@import '../../mixins';

.settings-form {
    width: 100%;

    .section-category {
        margin-bottom: 24px;
        @include media-breakpoint-down(xs) {
            margin-bottom: 20px;
        }

        h5 {
            margin-bottom: 16px;
            @include media-breakpoint-down(xs) {
                font-size    : 16px;
                margin-bottom: 8px;
            }
        }

        &.form {
            .cv-add-member-form {
                display    : flex;
                align-items: flex-end;
                flex-wrap  : wrap;
                margin     : -12px;

                > * {
                    margin: 12px;

                    &:first-child {
                        flex-grow: 1;
                    }
                }
            }

            .input-group {
                @include media-breakpoint-down(sm) {
                    display       : flex;
                    flex-direction: column;

                    .input-form {
                        margin-bottom: 16px;
                        @include media-breakpoint-down(xs) {
                            p {
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }

            p {
                font-size     : 14px;
                padding-bottom: 4px;
                @include media-breakpoint-down(xs) {
                    font-size: 12px;
                }
            }

            .input {
                height: 42px;

                &:focus {
                    box-shadow: none !important;
                    outline   : none !important;
                    border    : 1px solid $conversed-primary-light;
                }
                @include media-breakpoint-down(xs) {
                    height: 40px;
                }
            }

            .cv-input-dropdown-toggler {
                padding         : 0;
                border          : none;
                background-color: transparent;
                width           : 100%;
                min-width       : 288px;

                &::after {
                    display: none;
                }

                button {
                    width: 100%;
                }

                input {
                    border-radius: 2px;
                }
            }

            .cv-select-role-toggler {
                display         : flex;
                justify-content : space-between;
                align-items     : center;
                max-width       : 200px;
                height          : 40px;
                background-color: transparent;
                border          : 1px solid $conversed-gray;
                border-radius   : 2px;

                p {
                    line-height: 1;
                    margin     : 0;
                    padding    : 0;
                    color      : $conversed-gray;
                }

                &::after {
                    border-top  : 5px solid $conversed-gray;
                    border-right: 5px solid transparent;
                    border-left : 5px solid transparent;
                }

            }

            .cv-dropdown-menu {
                max-width    : auto;
                min-width    : 100%;
                padding      : 0;
                border-radius: 2px;

                a {
                    display        : flex;
                    justify-content: space-between;
                    align-items    : center;

                    &:active, &:hover {
                        background-color: $conversed-dropdown-active;
                    }
                }

                p {
                    margin-right: 8px;
                    padding     : 0;
                    font-size   : 12px;
                }

            }
        }

        &.drop-down {
            button {
                height: 32px;
                color : $conversed-gray;

                &:after {
                    display: none;
                }
            }

            .menu {
                margin    : 0;
                padding   : 0;
                border    : none;
                box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.3));

                .item {
                    width      : 180px;
                    height     : 36px;
                    padding    : 10px;
                    border     : none;
                    color      : $conversed-text;
                    display    : flex;
                    align-items: center;

                    &:hover {
                        background: $conversed-primary-05;
                        transition: all .3s ease-in-out;
                    }

                    &:active {
                        background: $conversed-dropdown-active;
                    }

                    &.title {
                        p {
                            font-size  : 14px;
                            font-weight: 500;
                        }
                    }

                    &.list {
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        &.check-box {
            label {
                font-size  : 13px;
                font-weight: 400;
            }

            .form-check {
                display    : flex;
                align-items: center;

                input {
                    margin-top  : 0;
                    margin-right: 8px;
                }
            }
        }

        &.table {
            box-shadow: 0 0 2px #B3B3B3;

            tbody, thead {
                border: none;

                tr {
                    &:hover {
                        background-color: $conversed-primary-02;
                        transition      : background-color .5s;
                    }
                }

                td, th {
                    border        : none;
                    vertical-align: middle;
                }

                td {
                    &.cv-delete-cell {
                        width: 24px;

                        span {
                            display: block;
                            width  : 24px;
                            color  : $conversed-danger;
                            margin : 0;
                        }
                    }

                    &.cv-member-roles {
                        width: 200px;

                        > div {
                            padding      : 8px;
                            border-radius: 2px;
                            background   : $conversed-primary-05;

                            &:hover {
                                background: $conversed-dropdown-active;
                                transition: background .8s;
                            }
                        }
                    }
                }
            }

            thead {
                border    : none;
                background: $conversed-primary-03;

                th {
                    width      : 100px;
                    white-space: nowrap;

                    &.cv-delete-head {
                        min-width: auto !important;
                        max-width: 32px;
                    }
                }

                p {
                    font-size  : 13px;
                    font-weight: 700;
                }
            }

            tbody {
                p {
                    font-size: 12px;
                }
            }
        }

        &.button-save {
            button {
                height: 40px;
            }

            span {
                font-size  : 13px;
                font-weight: 500;
            }
        }
    }

    .cv-switch {
        position      : relative;
        display       : inline-block;
        width         : 32px;
        height        : 16px;
        vertical-align: middle;

        input {
            opacity: 0;
            width  : 0;
            height : 0;
        }

        .cv-slider {
            position        : absolute;
            cursor          : pointer;
            top             : 0;
            left            : 0;
            right           : 0;
            bottom          : 0;
            background-color: $conversed-light-grey;
            transition      : .4s;
            border-radius   : 8px;

            &::before {
                position        : absolute;
                content         : "";
                height          : 12px;
                width           : 12px;
                border-radius   : 50%;
                left            : 4px;
                bottom          : 50%;
                background-color: #495057;
                transition      : .4s;
                transform       : translate(0px, 50%);
            }
        }

        input:checked + .cv-slider {
            background-color: $conversed-primary;
        }

        input:checked + .cv-slider:before {
            transform       : translate(12px, 50%);
            background-color: $conversed-white;

        }
    }

}

.role-selector {
    padding-left      : 8px;
    border-radius: 2px;
    background   : $conversed-primary-05;

    &:hover {
        background: $conversed-dropdown-active;
        transition: background .8s;
    }
}
