@import '../../variables';
@import '../../mixins';

.legal-page-content {
    h5 {
        margin-bottom: 24px;
    }

    .list-title {
        font-weight  : 500;
        margin-bottom: 8px;
    }

    p, ul {
        margin-bottom: 16px;
        font-weight  : 400;
    }

    ul {

        padding-left: 18px;
    }

    li {
        margin-bottom: 8px;

        &::marker {
            color: $conversed-primary;
        }
    }

}
