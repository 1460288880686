@import '../../variables';
@import '../../mixins';

.settings-cards-container {
    max-width      : 100%;
    display        : flex;
    justify-content: flex-start;
    align-items    : center;
    gap            : 16px;
    flex-wrap      : wrap;
    margin         : 0 0 24px;
}

.settings-card {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    max-width      : 100%;
    min-width      : 388px;
    padding        : 12px 24px;
    background     : $conversed-primary-02;
    box-shadow     : 0 0 2px 0 rgba(0, 0, 0, 0.3);
    @include media-breakpoint-down(lg) {
        min-width: 320px;
    }
    @include media-breakpoint-down(sm) {
        min-width: 100%;
        padding  : 12px;
    }

    .image-details-wrapper {
        display    : flex;
        align-items: center;

        > :not(:last-child) {
            margin: 0 8px 0 0;
        }
    }

    .label-color {
        width        : 22px;
        height       : 22px;
        border-radius: 4px;
        background   : crimson;
    }

    .card-image {
        width        : 60px;
        height       : 60px;
        @include media-breakpoint-down(sm) {
            width : 40px;
            height: 40px;
        }
        border-radius: 50%;
    }

    .details-wrapper {
        max-width: 250px;

        :first-child {
            margin: 0;
        }

        h5 {
            font-size    : 19px;
            line-height  : 1.2;
            white-space  : nowrap;
            text-overflow: ellipsis;
            width        : 200px;
            overflow-x   : clip;
            @include media-breakpoint-down(xs) {
                max-width: 80px;
            }
        }

        p {
            font-size  : 13px;
            line-height: 1.2;
            font-weight: 400;
            @include media-breakpoint-down(xs) {
                text-overflow: ellipsis;
                max-width    : 80px;
                overflow-x   : clip;
            }
        }
    }

    .icon-wrapper {
        display: flex;

        span {
            font-size: 18px;
            color    : $conversed-text;
            cursor   : pointer;

            &:hover {
                color: $conversed-primary;
            }

            &:not(:last-child) {
                margin: 0 24px 0 0;
            }
        }
    }

    .btn-primary {
        display: none;
    }
}
