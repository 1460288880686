@import '../../variables';
@import '../../mixins.scss';

.profile-picture-wrapper {
  position: relative;
  margin-bottom: 24px;

  .profile-picture {
    width: 96px;
    border-radius: 50%;
  }

  .icon-wrapper {
    position: absolute;
    bottom: 0;
    left: 72px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px $conversed-white;
    background: $conversed-primary;

    span {
      color: $conversed-white;
      font-size: 16px;
      margin: 4px 0 0 4px;
    }
  }
}

.account-information-wrapper {
  width: 100%;
  @include media-breakpoint-down(xs) {
    padding: 16px;
  }

  .section-category {
    margin-bottom: 24px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
    }

    h5 {
      margin-bottom: 16px;
      @include media-breakpoint-down(xs) {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }

    &.form {
      .input-group {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        @include media-breakpoint-down(sm) {
          display: flex;
          flex-direction: column;

          .input-form {
            margin-bottom: 16px;
            @include media-breakpoint-down(xs) {
              p {
                padding-bottom: 0;
              }
            }
          }
        }
        .input-form {
          max-width: 380px;
        }
      }

      p {
        font-size: 14px;
        padding-bottom: 4px;
        @include media-breakpoint-down(xs) {
          font-size: 12px;
        }
      }

      .input {
        height: 42px;

        &:focus {
          box-shadow: none !important;
          outline: none !important;
          border: 1px solid $conversed-primary-light;
        }
        @include media-breakpoint-down(xs) {
          height: 40px;
        }
      }
    }

    &.button-save-wrapper {
      display: flex;
      button {
        padding: 19px;
        &:not(:last-child) {
          margin-right: 24px;
        }

        &.button-cancel {
          background: $conversed-danger;
        }
      }

      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}
