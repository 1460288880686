@import '../../../../variables';
@import '../../../../mixins';

.message-in, .message-out {
    margin: 8px 16px;

    p {
        font-size  : 14px;
        font-weight: 400;
        line-height: 150%;
    }
}

.message-in {
    background   : $conversed-light-grey;
    border-radius: 0 8px 8px 8px;
    align-self   : flex-start;
}

.message-out {
    display   : flex;
    align-self: flex-end;
    width     : 288px;
    display   : block;
}

.carousel-element {
    background     : $conversed-light-blue;
    box-shadow     : inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    border-radius  : 8px;
    text-align     : center;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;

    &:last-child {
        margin-right: 0;
    }

    img {
        border-radius: 8px 8px 0 0;
        aspect-ratio : 1 / 1;
        width        : 288px;
        height       : auto;
    }

    p {
        font-size  : 14px;
        font-weight: 400;
        line-height: 150%;
        margin     : 8px 0;
    }

    .carousel-pillar {
        .pillar {
            padding   : 1px 0;
            background: $conversed-primary;

            p {
                color: $conversed-white;

            }

            &:last-child {
                border-radius: 0 0 8px 8px;
                margin-top   : 2px;
            }
        }

    }
}
