@import '../variables';
@import '../mixins';

.cv-button {
    &.clipboard {
        &.copy {
            display        : flex;
            justify-content: center;
            align-items    : center;
            background     : $conversed-light-grey;
            padding        : 8px;
            max-height     : 32px;
            border-radius  : 2px;
            color          : $conversed-gray;
            font-size      : 13px;

            span {
                font-size: 16px;
                margin   : 0 0 0 8px;
            }
        }
    }

    &.toggle {
        &.active, &.passive {
            height       : 32px;
            border       : none;
            font-size    : 13px;
            box-shadow   : none !important;
            border-radius: 2px;
        }

        &.active {
            background-color: $conversed-success !important;
        }

        &.passive {
            background-color: $conversed-light-grey !important;
            color           : $conversed-gray !important;
        }
    }
}
