//  ========================================================================
//  Variables
//  ========================================================================
$enable-grid-classes      : true !default;

$grid-breakpoints         : ( xs: 0, xm: 420px, sm: 576px, md: 768px, lg: 991px, xl: 1200px, xxl: 1500px) !default;
$container-max-widths     : ( sm: 540px, md: 720px, lg: 960px, xl: 1140px ) !default;

$grid-columns             : 12 !default;
$grid-gutter-width        : 32px !default;
$grid-row-columns         : 6 !default;

//  ========================================================================
//  Colors
//  ========================================================================
$conversed-white          : #fff !default;
$conversed-gray           : #999 !default;
$conversed-gray-lighter   : #F4F5F5 !default;
$conversed-black          : #000 !default;
$conversed-text           : #495057 !default;

$conversed-light-grey     : #f0f0f0 !default;

// Blues
$conversed-dropdown-active: #ebf0f6 !default;
$conversed-light-blue     : #eff3f8 !default;

// Others
$conversed-dropdown-active: #EBF0F6;

// Primary
$conversed-primary        : #3866A2 !default;
$conversed-primary-dark   : #2D528F !default;
$conversed-primary-light  : #437AB5 !default;
$conversed-primary-05     : #f5f7fa !default;
$conversed-primary-03     : #f9fbfc !default;
$conversed-primary-02     : #fbfcfd !default;

// Secondary
$conversed-secondary      : #F8C24C !default;
$conversed-secondary-dark : #F7B63D !default;
$conversed-secondary-light: #F9CE5B !default;
$conversed-secondary-03   : #fffdfa !default;

// Toastr colors
$conversed-info           : #1565C0 !default;
$conversed-danger         : #D84315 !default;
$conversed-warning        : #EF6C00 !default;
$conversed-success        : #4CAF50 !default;

// Sidebar Margin
$sidebar-margin           : 250px !default;
$sidebar-margin-mobile    : 262px !default;
$sidebar-margin-mini      : 80px !default;

// border
$border-black             : #1c1c1c !default;

// input elements
$border-checkbox          : #495057 !default;

// Chat private note
$conversed-note           : #FFFCF6 !default;

//  ========================================================================
//  Fonts
//  ========================================================================
$font-main                : 'Roboto', sans-serif;
//  font-weights:             : 200 300 400 500 700
