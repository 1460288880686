@import '../../mixins';
@import '../../variables';

.cv-chat-wrapper {
    @include media-breakpoint-down(lg) {
        margin-right: 18px;
    }
    @include media-breakpoint-down(sm) {
        position    : absolute;
        top         : 125px;
        right       : 0;
        width       : 100vw;
        margin-right: 0;
    }
}

.sidebar-button {
    @include media-breakpoint-down(sm) {
        display: none !important;
    }
}

.toggle-button {
    display         : flex;
    width           : 18px;
    background-color: $conversed-white;
    box-shadow      : inset 1px 0 0 rgba(0, 0, 0, 0.1), inset -1px 0 0 rgba(0, 0, 0, 0.1);
    transition      : all .5s;
    @include media-breakpoint-down(lg) {
        position  : absolute;
        top       : 117px;
        right     : 214px;
        width     : 18px;
        height    : calc(100vh - 117px);
        box-shadow: -4px 3px 4px 0 rgba(0, 0, 0, 0.05);
        z-index   : 5;
    }
    @include media-breakpoint-down(sm) {
        display: none !important;
    }

    span {
        color: $conversed-primary;
    }

    &-close {
        display         : flex;
        width           : 18px;
        background-color: $conversed-white;
        box-shadow      : inset 1px 0 0 rgba(0, 0, 0, 0.1), inset -1px 0 0 rgba(0, 0, 0, 0.1);
        transition      : all .5s;
        @include media-breakpoint-down(lg) {
            position: absolute;
            top     : 117px;
            right   : 0;
            width   : 18px;
            height  : calc(100vh - 117px);
        }

        span {
            color: $conversed-primary;
        }
    }
}

.empty-chat-state {
    padding: 0 30px 10px;
    width  : 100%;
}
