@import '../../variables';
@import '../../mixins';

.modal-inbox {
    > div {
        max-width: 640px;
        height   : auto;
        @media only screen and (max-width: 640px) {
            margin: 8px;

            h3 {
                font-size: 23px;
            }

        }

        p {
            font-size: 14px;
        }

        > div {
            border : none;
            padding: 24px;
            @media only screen and (max-width: 640px) {
                padding: 16px;
            }

            > div {
                padding: 0;

                &:nth-child(2) {
                    max-height  : 80vh;
                    margin-right: -12px;
                    padding     : 0 12px 0 0;
                    overflow-y  : auto;
                    overflow-x  : hidden;

                    &::-webkit-scrollbar {
                        width: 4px;
                    }

                    // track
                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }

                    // handle
                    &::-webkit-scrollbar-thumb {
                        background   : $conversed-light-grey;
                        border-radius: 10px;
                    }

                    // handle on hover
                    &::-webkit-scrollbar-thumb:hover {
                        background: $conversed-light-grey;
                    }
                }

            }

        }

    }

    .cv-row {
        margin-bottom: 16px;

        .cv-chips-wrapper {
            display        : flex;
            justify-content: flex-start;
            align-items    : flex-start;
            flex-wrap      : wrap;
            padding        : 6px 12px 2px;
            border         : 1px solid #ced4da;
            border-radius  : 4px;

            > span {
                margin-bottom: 4px;
            }

            span {
                display         : flex;
                align-items     : center;
                padding         : 2px 2px 2px 4px;
                font-size       : 11px;
                line-height     : 1;
                border-radius   : 2px;
                color           : $conversed-white;
                background-color: $conversed-primary;

                &:not(:last-child) {
                    margin-right: 4px;
                }

                span {
                    align-self: center;
                    cursor    : pointer;
                }
            }

            input {
                display      : flex;
                flex-grow    : 1;
                width        : auto;
                min-width    : 100px;
                height       : 19px;
                margin-bottom: 4px;
                padding      : 0;
                border       : none;
                appearance   : none;
            }
        }
    }

    .modal-header {
        border       : none;
        margin-bottom: 24px;
    }

    h3 {
        color: $conversed-primary;
    }

    .cv-choose-color {
        display        : flex;
        width          : 100%;
        justify-content: space-between;
    }

    .cv-color-input {
        width        : 26px;
        height       : auto;
        padding      : 0;
        border       : none;
        border-radius: 2px;

        &:focus {
            border-color: transparent;
            outline     : 0;
            box-shadow  : none;
        }
    }

    .input {
        height: 42px;

        &:focus {
            box-shadow: none !important;
            outline   : none !important;
            border    : 1px solid $conversed-primary-light;
        }
        @include media-breakpoint-down(sm) {
            height: 40px;
        }
    }

    .cv-footer {
        padding                   : 0;
        border-top                : none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius : 0;

        &.multiple-buttons {
            > button {
                margin-left: 16px;
            }
        }

        button {
            p {
                color: $conversed-white;
            }
        }
    }

    .modal-section {
        margin: 0 0 24px;

        .section-title {
            color : $conversed-text;
            margin: 0 0 16px;
        }

        .btn-primary {
            margin: 0 !important;
        }

        .pseudo-section-title {
            margin     : 0 0 16px;
            font-size  : 16px;
            font-weight: 500;
        }
    }

    .input-boolean {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        min-width      : fit-content;
        width          : 180px;
    }

    .modal-image-input-label {
        &:last-child {
            margin: 100px;
        }
    }

    .modal-image-input-label, .modal-label {
        margin     : 0 !important;
        line-height: 1.2;
        font-size  : 14px;
        font-weight: 500;
        white-space: nowrap;
    }

    .image-input-value {
        font-size  : 10px;
        line-height: 1;
        margin     : 4px 0;
    }

    .input-field {
        margin: 0 0 16px;
    }

    .modal-footer-custom {
        padding: 16px 12px 0 !important;
    }

    .color-picker-button {
        margin   : 0 303px 8px auto;
        border   : none !important;
        padding  : 0 !important;
        max-width: 40px;
    }

    .facebook-modal-body:nth-child(2) {
        padding: 0;
    }

    .cv-add-delete {
        display    : flex;
        align-items: center;
        margin     : 0 0 24px;

        &.add-only {
            span {
                color: $conversed-primary;
            }
        }

        &.locale-only {
            flex-direction : column;
            justify-content: center;

            span {
                font-size: 40px;
                color    : $conversed-gray;
            }

            p {
                margin: 8px 0 0;
            }
        }

        span {
            font-size: 24px;
            color    : $conversed-danger;
        }

        p {
            margin     : 0 0 0 8px;
            font-size  : 14px;
            font-weight: 500;
            line-height: 1.8;
        }
    }
}
