@import '../../mixins';
@import '../../variables';

.empty-page-wrapper {
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    margin-top     : 64px;

    .message {
        margin: 72px 0 0;

        > span {
            max-width  : 320px;
            margin     : 0 0 24px;
            text-align : center;
            line-height: 1.2;
            font-size  : 13px;
            font-weight: 400;
        }
    }
}
