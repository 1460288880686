@import '../../../variables';
@import '../../../mixins';

.wrapper {
    background: $conversed-primary-02;
    box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    max-height: calc(100vh - 312px);
    min-height: calc(100vh - 312px);

    /* width */
    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background   : $conversed-light-grey;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $conversed-light-grey;
    }

    .message-in, .message-out {
        max-width : 60%;
        padding   : 8px 12px;
        margin    : 2px 16px;
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);

        p {
            font-size  : 14px;
            font-weight: 400;
            line-height: 150%;
        }
    }

    .message-in {
        background   : $conversed-light-grey;
        border-radius: 0 8px 8px 8px;
        align-self   : flex-start;
    }

    .message-out {
        background   : $conversed-light-blue;
        border-radius: 8px 0 8px 8px;
        align-self   : flex-end;
    }

    .date {
        font-size : 11px;
        text-align: center;
        margin    : 12px 0 0;
    }

    .assign {
        font-size    : 11px;
        color        : $conversed-primary-light;
        margin-bottom: 24px;
        text-align   : center;
        line-height  : 150%;
        position     : relative;

        span {
            background: $conversed-primary-02;
            padding   : 0 12px;
            position  : inherit;
            z-index   : 1;
        }

        &::after, &::before {
            content   : "";
            display   : block;
            width     : 16%;
            height    : .5px;
            background: $conversed-primary-light;
            top       : 50%;
            position  : absolute;
        }

        &::before {
            left: 5%;
        }

        &::after {
            right: 5%;
        }
        @include media-breakpoint-down(lg) {
            &::after, &::before {
                display: none;
            }
        }
    }

    .typing-indicator-container {
        background   : #f2f4f5;
        width        : 3%;
        border-radius: 10px;
    }
}
