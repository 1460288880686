@import '../../../../variables';

.message-in, .message-out {
    max-width: 60%;
    padding  : 8px 12px;

    img {
        border-radius: 8px;
        aspect-ratio : 1 / 1;
        width        : 300px;
        height       : auto;
    }
}

.message-in {
    background   : $conversed-light-grey;
    border-radius: 0 8px 8px 8px;
    align-self   : flex-start;
}

.message-out {
    align-self: flex-end;
}
