@import '../../../mixins';
@import '../../../variables';

.node {
    border-radius: 8px;
    background   : #fff;
    border       : 2px solid $conversed-info;
    width        : 100%;
    min-width    : 45px;
    max-width    : 700px;
    min-height   : 60px;

    text-align   : center;

    &-entry {
        border: 2px solid $conversed-danger;
    }

    &-title {
        & > p {
            line-height: 30px;
            color      : $conversed-info;
        }
        display        : flex;
        flex-direction : row;
        padding-left   : 12px;
        padding-right  : 12px;
        width          : 100%;
        justify-content: space-between;
    }

    &-icon {
        font-size    : 20px;
        text-align   : center;
        display      : inline-block;
        padding-right: 12px;
        line-height  : 30px;
    }

    &-options {
        display     : flex;
        align-items : center;
        padding-left: 12px;
        font-size   : 18px;
        font-weight : 400;
        line-height : 1.5;
        color       : $conversed-text;
        border      : none;
        background  : none;

        &::after {
            margin: 0 0 0 12px;
        }

        &:focus, &:hover {
            color: $conversed-text;
        }

        &:focus {
            box-shadow: none;
        }

        a {
            padding  : 9px 10px 8px;
            font-size: 13px;

            &:active, &:hover {
                background-color: $conversed-primary-05;
                color           : $conversed-text;
            }
        }

        > div {
            transform         : translate(0px, 24px) !important;
            padding           : 0;
            border            : none;
            border-radius     : 0;
            -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
            -moz-box-shadow   : 0 4px 8px 0 rgba(0,0,0,0.3);
            box-shadow        : 0 4px 8px 0 rgba(0,0,0,0.3);
        }

        .active {
            background-color: $conversed-dropdown-active;
        }

    }

    &-separator {
        width : 100%;
        margin: 0;
        height: 1px;
        color : $conversed-info;
    }

    &-body > p {
        padding: 2px 24px;
    }
};

.image {
    width              : auto;
    max-height         : 250px;
    user-select        : none;
    -moz-user-select   : none;
    -webkit-user-drag  : none;
    -webkit-user-select: none;
    -ms-user-select    : none;
}
