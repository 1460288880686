@import '../../variables.scss';
@import '../../mixins.scss';

.chat-input {
    position   : relative;
    border-left: 1px solid #dee2e6;

    .chat-input-header {
        .tab {
            display: flex;
            padding: 6px 16px;
        }
        background: $conversed-light-blue;

        .input-tabs {
            .tab {
                padding: 6px 16px;

                p {
                    font-size: 14px;
                }

                &.reply {
                    &.active {
                        background: $conversed-secondary-03;

                        p {
                            color: $conversed-primary;
                        }
                    }
                }

                &.note {
                    &.active {
                        background: $conversed-note;

                        p {
                            color: $conversed-text;
                        }
                    }
                }
            }
        }
    }

    textarea {
        padding   : 8px;
        border    : none;
        resize    : none;
        background: $conversed-secondary-03;

        &.note {
            background: $conversed-note;
        }

        &:focus {
            outline: 0;
        }
    }

    .chat-input-bottom {
        display    : flex;
        align-items: center;
        background : $conversed-secondary-03;

        &.note {
            background: $conversed-note;
        }

        .input-icons {
            .icon {
                font-size: 20px;
                padding  : 16px 0 20px 16px;
                cursor   : pointer;
            }
        }

        .button-send {
            display         : flex;
            align-items     : center;
            padding         : 0 16px;
            height          : 40px;
            margin-right    : 16px;
            border          : none;
            background-color: $conversed-primary;

            p {
                margin-right: 10px;
                font-size   : 13px;
                font-weight : 500;
                color       : $conversed-white;
            }

            span {
                font-size: 18px;
            }
            @include media-breakpoint-down(sm) {
                background: none;

                p {
                    display: none;

                }

                span {
                    color: $conversed-text;
                }
            }
        }
    }
}

.emoji-container {
    position: absolute;
    bottom  : 65px;
    left    : 0;
    z-index : 24;
}
