@import '../../../mixins';
@import '../../../variables';

.node {
    display      : flex;
    align-items  : center;
    margin       : 8px 0;
    padding      : 8px 0 8px 8px;
    border       : 1px solid $conversed-light-grey;
    border-radius: 6px;
    cursor       : grab;

    > p, span {
        color: $conversed-text;

    }

    > p {
        font-size: 14px;
    }

    > span {
        margin-right: 8px;
    }

    &:hover {
        background-color: $conversed-primary-02;
        transition      : background-color .8s;

        > p, span {
            color     : $conversed-primary;
            transition: color .8s;
        }
    }

    &-container {
        width  : 214px;
        padding: 0 16px;

        & > p {
            margin   : 16px 0;
            font-size: 16px;
            color    : $conversed-info;
        }
    }
}
