@import '../../variables';
@import '../../mixins';

#sidebar-menu {
    min-width      : $sidebar-margin;
    max-width      : $sidebar-margin;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    z-index        : 99;
    background     : $conversed-white;
    box-shadow     : inset -1px 0 0 rgba(0, 0, 0, 0.1);
    transition     : all .6s ease-in-out;
    @include media-breakpoint-down(md) {
        position  : absolute;
        min-height: calc(100vh - 60px);
        min-width : $sidebar-margin-mobile;
        max-width : $sidebar-margin-mobile;
        box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.15);
        transform : translateX(-100%);
        transition: all .6s ease-in-out;

        .sidebar-item {
            transition: all .6s;

            h5 {

                opacity   : 0;
                transform : translateX(-25px);
                visibility: hidden;
            }

        }
    }

    &.active {
        min-width: $sidebar-margin-mini;
        max-width: $sidebar-margin-mini;
        @include media-breakpoint-down(md) {
            min-width : $sidebar-margin;
            max-width : $sidebar-margin;
            transform : translateX(0);
            box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
        }

        .sidebar-item {
            h5 {
                opacity       : 0;
                transform     : translateX(-25px);
                visibility    : hidden;
                float         : right;
                width         : calc(100% - #{$sidebar-margin});
                pointer-events: none;
                @include media-breakpoint-down(md) {
                    visibility: visible;
                    opacity   : 1;
                    transform : translateX(0);
                }
            }
        }

        .sidebar-footer {
            bottom: 0;

            .footer {
                height    : 0;
                padding   : 0;
                opacity   : 0;
                visibility: visible;
                @include media-breakpoint-down(md) {
                    padding      : 10px 30px;
                    transition   : all .5s ease-in-out;
                    height       : 48px;
                    margin-bottom: 30px;
                    opacity      : 1;
                }
            }
        }
    }

    a {
        text-decoration: none;
    }

    .sidebar-item {
        padding: 12px 30px;

        &:active {
            background: $conversed-dropdown-active;
            transition: all .2s ease-in-out;

            .wrapper {
                h5, span {
                    transition: all .2s ease-in-out;
                    color     : $conversed-primary;
                }
            }
        }

        &:hover {
            background: $conversed-primary-05;
            transition: all .2s ease-in-out;

            .wrapper {
                h5, span {
                    transition: all .2s ease-in-out;
                }
            }
        }

        h5 {

            transition: all .5s ease-in-out;
        }

        .wrapper {
            display: flex;
            color  : $conversed-text;

            span {
                padding-right: 12px;
            }
        }
        @include media-breakpoint-down(xs) {
            padding: 10px 15px;
        }
    }

    .sidebar-footer {
        .footer {
            padding      : 10px 30px;
            transition   : all .5s ease-in-out;
            height       : 48px;
            margin-bottom: 30px;
            @include media-breakpoint-down(xs) {
                padding: 10px 15px;
            }

            .wrapper {
                display: flex;

                a, p {
                    white-space: nowrap;
                }

                p {
                    padding-right: 4px;
                    color        : $conversed-text;
                }

                a {
                    text-decoration: none;
                    color          : $conversed-primary;
                }
            }

            .legals-wrapper {
                display        : flex;
                justify-content: space-between;

                a {
                    font-size: 11px;
                    color    : $conversed-gray;
                }
            }
        }
    }
}
