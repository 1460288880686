.container {
    box-shadow    : 0 0 2px 1px rgba(0,0,0,.08);
    top           : 10px;
    left          : 10px;
    position      : absolute;
    z-index       : 5;

    // Align items horizontallys
    display       : flex;
    flex-direction: row;
}

.button {
    align-items        : center;
    background         : #fefefe;
    border-bottom      : 1px solid #eee;
    box-sizing         : content-box;
    cursor             : pointer;
    display            : flex;
    justify-content    : center;
    padding            : 5px;
    -webkit-user-select: none;
    -moz-user-select   : none;
    -ms-user-select    : none;
    user-select        : none;
    max-height         : 16px;
    max-width          : 16px;

    &:hover {
        background-color: #f4f4f4;
    }

    span {
        font-size: 14px;
    }
}
