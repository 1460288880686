@import '../../variables';
@import '../../mixins';

.settings-content-title {
    display      : flex;
    align-items  : center;
    margin-bottom: 24px;

    h3 {
        margin   : 0 12px 0 0;
        font-size: 28px;
        color    : $conversed-primary;
    }

    span {
        font-size: 24px;

        &:first-child {
            color  : $conversed-text;
            margin : 0 12px 0 0;
            display: none;
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }

        &:last-child {
            color: $conversed-primary;
        }
    }

}
