@import '../../mixins';
@import '../../variables';

.app-bar {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    box-shadow     : 0 0 4px #b3b3b3;
    height         : 60px;
    position       : relative;
    z-index        : 100;
    padding        : 0 16px;
    @include media-breakpoint-down(xs) {
        padding: 0 12px;
    }

    .menu-logo-wrapper {
        display       : flex;
        flex-direction: row;
        align-items   : center;

        .cv-menu {
            margin-right: 44px;
            @include media-breakpoint-down(xs) {
                margin-right: 12px;
            }

            span {
                font-size     : 40px;
                vertical-align: middle;
                color         : $conversed-text;
            }
        }

        .cv-logo {
            width       : 140px;
            margin-right: auto;
            @include media-breakpoint-up(sm) {
                width: 164px;
            }
        }
    }

    .navbar-collapse {
        flex-grow: 0;
    }

    .cv-nav-wrapper {
        display    : flex;
        align-items: center;

        > div:not(:last-child) {
            > span {
                vertical-align: middle;
                font-size     : 17px;
                color         : $conversed-text;
            }

            margin-right: 24px;
            @include media-breakpoint-down(xm) {
                margin-right: 0;
            }
        }

        .user-avatar {
            max-width: 30px;
            height   : auto;
            cursor   : pointer;
            @include media-breakpoint-up(sm) {
                margin-right: 12px;

            }
        }

        .cv-nav-break {
            display         : none;
            width           : 1px;
            height          : 24px;
            background-color: $conversed-gray;
            @include media-breakpoint-up(sm) {
                display: block;
            }
        }
    }
}

.cv-user {
    display    : flex;
    align-items: center;

    .user-name-type {
        display       : none;
        flex-direction: column;
        font-size     : 16px;
        font-weight   : 500;
        line-height   : 1.2;
        color         : $conversed-text;
        @include media-breakpoint-up(sm) {
            display: flex;
        }

        span {
            font-size  : 11px;
            color      : $conversed-primary;
            font-weight: 400;
        }
    }
}
