@import '../../variables';
@import '../../mixins';

.info-wrapper {
    display        : flex;
    justify-content: flex-start;
    align-items    : center;

    // TODO: Set border radius to user avatar in _base.scss
    .user-avatar {
        max-width    : 30px;
        margin-right : 12px;
        border-radius: 50%;
    }

    p {
        line-height: 1.5;
    }
}

.switch-role-dropdown {
    .cv-current-roles-wrapper {
        display        : flex;
        justify-content: flex-start;
        align-items    : center;
        flex-wrap      : wrap;
        width          : 200px;

        div {
            display      : flex;
            align-items  : center;
            height       : 16px !important;
            padding      : 0 4px;
            border       : none;
            border-radius: 2px;
            font-size    : 11px;
            font-weight  : 400;
            cursor       : default;
            white-space  : nowrap;
            color        : $conversed-white;
            background   : $conversed-primary;

            &:not(:last-child) {
                margin-right: 4px;
            }
        }
    }

    .cv-toggler {
        background-color: transparent !important;
        border          : none;

        &:focus {
            box-shadow: none !important;
        }

        &::after {
            border-top  : 5px solid $conversed-text;
            border-right: 5px solid transparent;
            border-left : 5px solid transparent;
        }
    }

    .cv-dropdown-menu {
        width        : 100%;
        padding      : 0;
        border-radius: 2px;
        transform    : translate(0px, 56px) !important;

        a {
            &:active, &:hover {
                background-color: $conversed-dropdown-active;
            }
        }
    }

}

.cv-granted {
    p {
        line-height: 1.5;
        white-space: nowrap;
    }

}
