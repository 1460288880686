/* Base Elements */
.table, a, body, h1, h2, h3, html, label, ol, p, table, ul {
    margin : 0;
    padding: 0;
}

body {
    @include font($family: $font-main, $weight: 500, $color: $conversed-text, $line-height: 28px);

    * {
        box-sizing: border-box;
    }
    overflow: hidden;
}

button.btn {
    @include displayFlex($display: flex, $direction: row, $justify-content: center, $align-items: center);
    // @include padding($full: 0px 24px);
    @include borderBox($border: 1px solid $conversed-white, $radius: 2px);
    margin: 0;

    &.btn-primary {
        /* primary buttons */
        @include blur($background-color: $conversed-primary);
        max-height: 40px;
        height    : 40px;
        font-size : 13px;
    }

    &.btn-secondary {
        /* secondary buttons */
        @include blur($background-color: $conversed-secondary);
    }

    &.btn-grey {
        /* tertiary buttons */
        @include blur($background-color: $conversed-gray);
    }

    &:focus {
        outline   : none;
        box-shadow: none;
    }

    &.btn-stroke {
        padding      : 0 6px 0 12px;
        height       : 32px;
        border-radius: 2px;
        border       : 1px solid $conversed-gray;
        background   : $conversed-white !important;

        p {
            font-size  : 13px;
            font-weight: 500;
            color      : $conversed-gray;
        }

        > :not(:last-child()) {
            margin-right: 4px;
        }

        &:focus {
            box-shadow: none !important;
            outline   : none !important;
            border    : 1px solid $conversed-gray !important;
        }
    }
}

.general-add-button {
    display    : flex;
    align-items: center;
    border     : none;
    background : none;
    padding    : 0;

    &:hover {
        .icon {
            box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);

            span {
                color: $conversed-primary;
            }
        }

        .label {
            color: $conversed-primary;
        }
    }

    &.with-text {
        margin: 0 0 24px;

        .label {
            display: block;
        }
    }

    .icon {
        height       : 40px;
        border-radius: 50%;
        padding      : 0;
        background   : $conversed-primary-02;
        transition   : all .5s;
        box-shadow   : 0 0 4px rgba(0, 0, 0, 0.3);

        span {
            font-size : 40px;
            color     : $conversed-gray;
            transition: all .5s;
        }
    }

    .label {
        display    : none;
        margin     : 0 0 0 16px;
        transition : all .5s;
        font-size  : 16px;
        font-weight: 500;
        color      : $conversed-text;
    }
}

h1, h2, h3, h5, h5, p {
    padding: 0;
    margin : 0;
}

h1 {
    @extend .heading-fontsize;
    @include font($size: 40px);
}

h2 {
    @extend .heading-fontsize;
    @include font($size: 33px);
}

h3 {
    @extend .heading-fontsize;
    @include font($size: 28px);
}

h4 {
    @extend .heading-fontsize;
    @include font($size: 23px);
}

h5 {
    @extend .heading-fontsize;
    @include font($size: 19px);
}

p {
    @extend .heading-fontsize;
    @include font($size: 16px, $font-style: normal, $weight: 400);
}

.conversed-checkbox {
    @include conversed-checkboxes-radios(18px, 19px, 1px, 0, 30px, 0px);

    line-height: 22px;
    display    : flex;
    padding    : 8px 0;
}

img.circle {
    border-radius: 50%;
}

// Needed for spinner element to be on top of other ui elements on loading
.global {
    z-index: 10000 !important;
}

.clickable {
    cursor: pointer;
}

.link-text {
    @include font($size: 13px, $font-style: normal, $weight: normal, $line-height: 120%, $color: $conversed-primary);
    text-decoration-line: underline;
}

// removes X from search fields on Chrome
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

::backdrop {
    background-color: white;
}

// Needed to customize slick-slider arrows
.slick-next, .slick-prev {
    width     : 24px;
    height    : 24px;
    background: $conversed-white;
    z-index   : 10;

    &::before {
        content: "";
        opacity: 1;
        display: inline-block;
        color  : $conversed-primary;
    }

    &:active, &:focus, &:hover {
        background: $conversed-white;
    }
}

.slick-next {

    transform    : translate(-25px,-50%);
    border-radius: 2px 0 0 2px;

    &::before {
        border-left  : 5px solid;
        border-bottom: 5px solid transparent;
        border-right : 0;
        border-top   : 5px solid transparent;
    }
}

.slick-prev {
    transform    : translate(25px,-50%);
    border-radius: 0 2px 2px 0;

    &::before {
        border-right : 5px solid;
        border-bottom: 5px solid transparent;
        border-left  : 0;
        border-top   : 5px solid transparent;
    }
}

.break-word {
    overflow-wrap:      break-word;
     word-wrap:         break-word;
    -ms-word-break:     break-all;
    word-break:         break-all;
    word-break:         break-word;
    -ms-hyphens:        auto;
    -moz-hyphens:       auto;
    -webkit-hyphens:    auto;
    hyphens:            auto;
  }