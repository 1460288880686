@import '../../mixins';
@import '../../variables';

.cv.dropdown.custom {
    &.inbox, &.more {
        @include media-breakpoint-down(md) {
            min-width: 100%;
        }
    }

    .cv.toggler.custom {
        min-width      : 160px;
        max-height     : 32px;
        display        : flex;
        justify-content: space-between;
        background     : none;
        color          : $conversed-text;
        border         : 1px solid $conversed-gray;
        border-radius  : 2px;
        box-shadow     : none;

        &::after {
            border-top  : 5px solid $conversed-gray;
            border-right: 5px solid transparent;
            border-left : 5px solid transparent;
        }

        &.inbox, &.more {
            min-width: 0;

            p {
                font-size: 13px;
            }
            @include media-breakpoint-down(md) {
                min-width: 100%;
                border   : none;
            }
        }

        &.inbox {
            &::after {
                margin: 0 0 0 4px;
            }
            @include media-breakpoint-down(md) {
                min-width: 100%;
                border   : none;
            }

            & + .cv.menu.custom {
                @include media-breakpoint-down(md) {
                    position: absolute;
                }
            }

        }

        &.more {
            padding: 0;
            border : none;
            @include media-breakpoint-down(md) {
                padding: 0 10px;
            }

            p {
                display: none;
                @include media-breakpoint-down(md) {
                    display: inline;
                }
            }

            &::after {
                display: none;
            }
        }

        &.add-label {
            min-width : 0;
            max-height: 16px;
            margin    : 0 0 0 8px;
            padding   : 0;
            border    : none;

            span {
                font-size  : 16px;
                font-weight: bolder;
                color      : $conversed-primary;
            }

            &::after {
                display: none;
            }
        }
    }

    .cv.menu.custom {
        padding      : 0;
        border       : none;
        border-radius: 0;
        box-shadow   : 0 4px 8px 0 rgb(0 0 0 / 30%);

        .cv.divider.custom {
            margin: 0;
        }

        .cv.item.custom {
            display        : flex;
            justify-content: space-between;
            align-items    : center;
            padding        : 0 10px;
            height         : 36px;
            font-size      : 13px;
            color          : $conversed-text;

            p {
                font-size: 13px;
            }

            &:hover {
                background-color: $conversed-dropdown-active;
            }
        }

        &.add-label {
            inset    : auto !important;
            transform: translate(-25%, 0) !important;
        }

        &.more {
            inset    : auto !important;
            transform: translate(-85%, 0) !important;
            @include media-breakpoint-down(md) {
                transform: translate(-0%, 0) !important;
            }
        }

        .cv-dropdown-search {
            padding: 8px 10px;

            &:active, &:focus {
                background-color: $conversed-dropdown-active !important;
            }

            .cv-dropdown-search-wrapper {
                display        : flex;
                justify-content: space-between;
                align-items    : center;
                width          : 100%;
                cursor         : text;

                form {
                    position     : relative;
                    width        : 100%;
                    height       : 32px;
                    padding      : 0 0 0 12px;
                    border       : 1px solid $conversed-gray;
                    border-radius: 0 2px 2px 0;
                    background   : $conversed-white;

                    input {
                        padding      : 0;
                        width        : 100%;
                        border       : none;
                        border-radius: 0;
                        outline      : none;
                        font-size    : 13px;
                        color        : $conversed-gray;

                        &:focus {
                            box-shadow: none;
                        }

                    }

                    .search-submit {
                        position        : absolute;
                        right           : 10px;
                        padding         : 0;
                        color           : $conversed-gray;
                        border          : none;
                        background-color: none;

                        &:hover {
                            background: none !important;
                        }

                        &:focus {
                            box-shadow: none;
                        }

                        span {
                            vertical-align: middle;
                            font-size     : 15px;
                            font-weight   : 700;
                            line-height   : 2.1;
                        }
                    }
                }
            }
        }
    }
}
