@import '../../../mixins';
@import '../../../variables';

.chatlist-wrapper {
    width     : 320px;
    background: $conversed-white;
    box-shadow: 1px 0 0 0 $conversed-light-grey;
    @include media-breakpoint-down(sm) {
        position: absolute;
        top     : 60px;
        right   : 0;
        width   : 100vw;
    }
}

.chatlist-message-wrapper {
    overflow-y: scroll;
    max-height: calc(100vh - 200px);
    background: $conversed-primary-05;

    /* width */
    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background   : $conversed-gray;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $conversed-gray;
    }

    a {
        text-decoration: none;
    }
}

.chatlist-header {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    align-items    : center;
    width          : 320px;
    min-height     : 140px;
    max-height     : 140px;
    padding        : 12px;
    background     : $conversed-white;
    @include media-breakpoint-down(sm) {
        width: 100vw;
        // height: calc(100vh - 60px);
    }

}

.chatlist-top-header {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    position       : relative;
    width          : 100%;

    .group-dropdown {
        display    : flex;
        align-items: center;
        padding    : 0;
        font-size  : 18px;
        font-weight: 400;
        line-height: 1.5;
        color      : $conversed-text;
        border     : none;
        background : none;

        &::after {
            margin: 0 0 0 12px;
        }

        &:focus, &:hover {
            color: $conversed-text;
        }

        &:focus {
            box-shadow: none;
        }

        a {
            padding  : 9px 10px 8px;
            font-size: 13px;

            &:active, &:hover {
                background-color: $conversed-primary-05;
                color           : $conversed-text;
            }
        }

        > div {
            transform         : translate(0px, 24px) !important;
            padding           : 0;
            border            : none;
            border-radius     : 0;
            -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
            -moz-box-shadow   : 0 4px 8px 0 rgba(0,0,0,0.3);
            box-shadow        : 0 4px 8px 0 rgba(0,0,0,0.3);
        }

        .active {
            background-color: $conversed-dropdown-active;
        }

    }

    .status-dropdown {
        &::after {
            border-top   : .4em solid;
            border-right : .4em solid transparent;
            border-bottom: 0;
            border-left  : .4em solid transparent;
            margin       : 6px 3px 5px 5px;
        }

        > button {
            display      : flex;
            align-items  : center;
            height       : 32px;
            padding      : 0 8px 0 16px;
            font-size    : 13px;
            font-weight  : 500;
            line-height  : 1.2;
            color        : $conversed-gray;
            border       : 1px solid $conversed-gray;
            border-radius: 2px;
            background   : none;
        }

        a {
            padding  : 4px 10px;
            font-size: 13px;

            &:active, &:hover {
                background-color: $conversed-primary-05;
                color           : $conversed-text;
            }
        }

        > div {
            transform         : translate(-71px, 34px) !important;
            padding           : 0;
            border            : none;
            border-radius     : 0;
            -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
            -moz-box-shadow   : 0 4px 8px 0 rgba(0,0,0,0.3);
            box-shadow        : 0 4px 8px 0 rgba(0,0,0,0.3);
        }

        .active {
            background-color: $conversed-dropdown-active;
        }
    }
}

.chatlist-search {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    width          : 100%;

    .label-dropdown {
        > button {
            display      : flex;
            align-items  : center;
            max-height   : 32px !important;
            padding      : 8px 13px 7px;
            font-size    : 12px;
            font-weight  : 400;
            line-height  : 1.2;
            border-radius: 2px 0 0 2px;
            box-shadow   : none !important;

            &::after {
                border-top   : 4px solid;
                border-right : 4px solid transparent;
                border-bottom: 0;
                border-left  : 4px solid transparent;
                margin       : 5px 0 5px 5px;
            }
        }

        .menu {
            margin    : 0;
            padding   : 0;
            border    : none;
            box-shadow: (0px 4px 8px rgba(0, 0, 0, 0.3));

            a {
                padding: 4px 10px 3px;

                &:active, &:hover {
                    background-color: $conversed-primary-05;
                    color           : $conversed-text;
                }
            }

            > div {
                transform         : translate(0, 34px) !important;
                padding           : 0;
                border            : none;
                border-radius     : 0;
                -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
                -moz-box-shadow   : 0 4px 8px 0 rgba(0,0,0,0.3);
                box-shadow        : 0 4px 8px 0 rgba(0,0,0,0.3);
            }

            .active {
                background-color: $conversed-dropdown-active;
            }
        }

    }

    form {
        position     : relative;
        width        : 100%;
        height       : 32px;
        padding      : 0 0 0 12px;
        border-top   : 1px solid $conversed-gray;
        border-right : 1px solid $conversed-gray;
        border-bottom: 1px solid $conversed-gray;
        border-radius: 0 2px 2px 0;

        input {
            padding      : 0;
            width        : 100%;
            border       : none;
            border-radius: 0;
            outline      : none;
            font-size    : 13px;
            color        : $conversed-gray;

            &:focus {
                box-shadow: none;
            }

        }

        .search-submit {
            position        : absolute;
            right           : 10px;
            padding         : 0;
            color           : $conversed-gray;
            border          : none;
            background-color: none;

            &:hover {
                background: none !important;
            }

            &:focus {
                box-shadow: none;
            }

            span {
                vertical-align: middle;
                font-size     : 15px;
                font-weight   : 700;
                line-height   : 2.1;
            }
        }
    }
}

.chatlist-tabs {
    display        : flex;
    justify-content: space-around;
    align-items    : center;
    position       : relative;
    width          : 100%;

    &::after {
        content   : '';
        position  : absolute;
        bottom    : -4px;
        width     : 100%;
        height    : 1px;
        background: $conversed-gray;
    }

    > div {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
    }

    span {
        font-size  : 12px;
        font-weight: 500;
        color      : $conversed-text;

        &:last-child {
            margin-left  : 4px;
            padding      : 6px;
            font-size    : 10px;
            border-radius: 12px;
            line-height  : 1;
            background   : $conversed-gray-lighter;
        }
    }

    .active-tab {
        position: relative;

        span {
            &:first-child {
                color: $conversed-primary;
            }
        }

        &::after {
            content      : '';
            position     : absolute;
            left         : -28px;
            right        : -28px;
            bottom       : -5px;
            height       : 3px;
            border-radius: 4px;
            background   : #3866A2;
            z-index      : 1;
        }
    }
}

.cv-card-wrapper {
    // margin-bottom  : 1500px;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    padding        : 14px 8px;

    &.active {
        position  : relative;
        padding   : 14px 8px 14px 12px;
        background: $conversed-dropdown-active !important;

        &::before {
            content      : '';
            position     : absolute;
            left         : 0;
            top          : 0;
            bottom       : 0;
            width        : 4px;
            background   : $conversed-primary;
            border-radius: 4px;
        }
    }

    &:hover {
        background: $conversed-primary-05 !important;
    }

    img {
        margin-right: 8px;
    }

    .cv-card-name {
        font-size  : 16px;
        font-weight: 400px !important;
        line-height: 1.2;
        color      : $conversed-text;

        span {
            margin     : 4px 8px 0 0;
            font-size  : 10px;
            font-weight: 400;
            color      : $conversed-white;
            background : $conversed-danger;
        }
    }

    small {
        font-size  : 10px;
        line-height: 1;
        white-space: nowrap;
    }
}
