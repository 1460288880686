.block {
    align-items: center;
    display    : flex;
    height     : 17px;

    .dot {
        animation    : mercuryTypingAnimation 1.5s infinite ease-in-out;
        border-radius: 2px;
        display      : inline-block;
        height       : 4px;
        margin-right : 2px;
        width        : 4px;
    }

    .dot:nth-child(1) {
        animation-delay: 200ms;
    }

    .dot:nth-child(2) {
        animation-delay: 300ms;
    }

    .dot:nth-child(3) {
        animation-delay: 400ms;
    }
}

.container .dot {
    background-color: #90949c;
}
@keyframes mercuryTypingAnimation {
    0% {
        -webkit-transform: translateY(0px);
    }

    28% {
        -webkit-transform: translateY(-5px);
    }

    44% {
        -webkit-transform: translateY(0px);
    }
}
